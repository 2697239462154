<template>
  <div>
    <el-container>
      <el-header class="Headers">
        <el-button type="danger" class="btn_Publisher" @click="ToWriting">发布文章<i class="el-icon-s-promotion"></i></el-button>
      </el-header>
      <el-main class="Banner">
        <div class="FirstType">
          <div class="ArticleIndex" @click="ToArticleIndex" id="index"  :class="activeClass == 'index' ? 'active':''">首页</div>
          <div class="ArticleQueAns" @click="ToArticleQueAns" id="queans"  :class="activeClass == 'queans' ? 'active':''">问答</div>
        </div>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// import '@/assets/rem.js'
// import { GetUserBlog } from '../../api/blog';
export default {
    name:'EditingCenter',
    data(){
      return{
        bloglist:[],
        activeClass:'index',
      }
    },
    created(){
      // this.GetBlog()
    },
    mounted(){

    },
    methods:{
      // async GetBlog(){
      //   let res= await GetUserBlog()
      //   this.bloglist = res.data.list
      //   console.log(this.bloglist)
      // },
      ToWriting(){
        this.$router.push({path:'/md_article'})
      },
      ToArticleIndex(e){
        let initid = e.currentTarget.id
        this.activeClass = initid
        this.$router.push({path:'/account/editingcenter/article'})
      },
      ToArticleQueAns(e){
        let initid = e.currentTarget.id
        this.activeClass = initid
        this.$router.push({path:'/account/editingcenter/queans'})
      }
    }
}
</script>

<style scoped>
.Headers{
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  line-height: 60px;
  width: 100%;
  box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
  position: sticky;
  top: 0;
  background: #ffffff;
  padding: 0 !important;
  z-index: 20;
  border-radius: 4px;
}
.Banner{
  padding: 0px;
  margin-top: 20px;
  box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
}
.btn_Publisher{
  position: absolute;
  right: 10px;
  border-radius: 20px;
}
.FirstType{
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
}
.ArticleIndex{
  font-size: 14px;
  height: 100%;
  width: 50px;
  padding: 0 5px;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
  color: white;
}
.ArticleQueAns{
  font-size: 14px;
  height: 100%;
  width: 50px;
  padding: 0 5px;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
  color: white;
}
.active{
  font-weight: 600;
}

</style>